import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

export const BudgetItem = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const handleBackButton = () => {
    navigate(-1);
  }

  return (
    <div>
      Página Orçamento {params.id}<br/>
      Dado: {searchParams.get('data')}
      <hr/>
      <button onClick={handleBackButton} >Voltar</button>
    </div>
  );
}
import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { About } from './pages/About';
import { Budget } from './pages/Budget';
import { NotFound } from './pages/NotFound';
import { BudgetItem } from './pages/BudgetItem';

function App() {
  return (
    <div className="App">
      <div className='body' >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sobre" element={<About />} />
          <Route path="/orcamento" element={<Budget />} />
          <Route path="/orcamento/:id" element={<BudgetItem />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;

export const ChartBarHorizontal = [
  {
    id: 1,
    title: '1 ANO',
    value: 10560,
  },
  {
    id: 2,
    title: '2 ANOS',
    value: 21120,
  },
  {
    id: 3,
    title: '3 ANOS',
    value: 31680,
  },
  {
    id: 4,
    title: '4 ANOS',
    value: 42240,
  },
  {
    id: 4,
    title: '5 ANOS',
    value: 52800,
  },
  {
    id: 4,
    title: '6 ANOS',
    value: 63360,
  },
  {
    id: 4,
    title: '7 ANOS',
    value: 73920,
  },
  {
    id: 4,
    title: '8 ANOS',
    value: 84480,
  },
  {
    id: 4,
    title: '9 ANOS',
    value: 95040,
  },
  {
    id: 4,
    title: '10 ANOS',
    value: 105600,
  }
];
export const Data = 
{
    header: {
        data: '21/06/2022',
        diretor: 'Alisson Lima',
        contato: '(62) 99654-6596',
        cliente: 'José Messias de Carvalho'
    },
    consumo: {
        valorKwMes: 1.10,
        consumoKwMes: 1500
    },
    proposta: {
        valor: 11.000,
        parcelamento1: 260,
        parcelamento2: 210,
        parcelamento3: 180 
    },
    geraRegiao: {
        local: 'Centro-oeste',
        irmm2: 5.2, 
        kwp: 2.12,
        medKwMes: 270,
        grafico: [291,299,275,273,252,242,250,300,292,296,288,296]
    },
    kit: {
        qtdPainel: 4,
        areaOcupada: 10
    }
}
import { Link } from 'react-router-dom';

export const Home = () => {
  return (
    <div>
      <h1>Página Home</h1>
      <ul>
        <li><Link to="/sobre">Sobre</Link></li>
        <li><Link to="/orcamento">Orçamento</Link></li>
      </ul>
    </div>
  );
}
import styled from "styled-components";

export const ButtonPrintFloat = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
`;
export const ButtonPrint = styled.button`
  width: 50px;
  height: 50px;
  background-color: #157752;
  color: #FFF;
  font-size: 14px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 12px 2px #999;

  &:hover {
    background-color: #126042;
  }
`;
import React, { useRef, useState, useEffect } from 'react';
import './styles.css';
import { useSearchParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import BarChart from '../../components/BarChart';
import PieChart from '../../components/PieChart';
import DoughnutChart from '../../components/DoughnutChart';
import * as C from './styled';
import { ChartBar } from '../../ChartBar';
import { ChartBarHorizontal } from '../../ChartBarHorizontal';
import { Data } from '../../components/Data';

import headerImage from '../../images/capa-orcamento3.png';
import logoImage from '../../images/logo.png';
import sistemaImage from '../../images/sistema.png';
import placaSolar from '../../images/placa-solar.png';
import modulosImage from '../../images/modulos.png';
import placaImage from '../../images/placa.png';
import qrcode from '../../images/qrcode.png';

export const Budget = () => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="container">
      <C.ButtonPrintFloat>
        <C.ButtonPrint onClick={handlePrint}>Salvar PDF</C.ButtonPrint>
      </C.ButtonPrintFloat>
      <ComponentToPrint ref={componentRef} />
    </div>
  );
}


class ComponentToPrint extends React.Component {
  render() {
    return (
      <Container />
    );
  }
}

export const  Container = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [data, setData] = useState(JSON.parse(searchParams.get('data')) ?? Data);
  const [table2, setTable2] = useState([
    {
      title: '1 Ano',
      value: 0,
    },
    {
      title: '5 Anos',
      value: 0,
    },
    {
      title: '10 Anos',
      value: 0,
    },
    {
      title: '20 Anos',
      value: 0,
    }
  ]);
  const [payback, setPayback] = useState([0,0,0,0,0,0,0,0,0,0,]);

  const [chartPie, setChartPie] = useState(null);
  const [chartBar, setChartBar] = useState(null);
  const [chartDougnut, setChartDougnut] = useState(null);

  const [chartBarHorizontal, setChartBarHorizontal] = useState({
    labels: ChartBarHorizontal.map((item)=>item.title),
    datasets: [{
      label: "ÍNDICE DE RETORNO ANUAL",
      indexAxis: 'y',
      data: ChartBarHorizontal.map((item)=>item.value),
      backgroundColor: ['blue']
    }]
  });

    
  useEffect(()=>{
    let valor = data.consumo.valorKwMes = toNumber(data.consumo.valorKwMes);
    let consumo = data.consumo.consumoKwMes = toNumber(data.consumo.consumoKwMes);

    let valorFloat = data.consumo.valorKwMes.replace(".","");
    let consumoFloat = data.consumo.consumoKwMes.replace(".","");

    let gastoMes = valor * consumo;



    let newTable2 = table2;
    newTable2[0].value = gastoMes * (12*1);
    newTable2[1].value = gastoMes * (12*5);
    newTable2[2].value = gastoMes * (12*10);
    newTable2[3].value = gastoMes * (12*20);

    setTable2(newTable2);

    let newPayback = [];
    for(let i = 1; i <=10; i++) {
      newPayback.push(gastoMes * (i*12));
    }
    setPayback(newPayback);

  }, [])

  useEffect(()=>{
    let labels = table2.map((item)=>item.title);
    let data = table2.map((item)=>item.value);
    
    setChartPie({
      labels: labels,
      datasets: [{
        label: "Quanto você gasta",
        data: data,
        backgroundColor: ['pink', 'yellow', 'orange', 'red']
      }]})
  }, []);

  useEffect(()=>{
    let grafico = data.geraRegiao.grafico.split(',');
    grafico.forEach(item => parseFloat(item).toFixed(2));

    setChartBar({
      labels: ChartBar.map((item)=>item.title),
      datasets: [{
        label: "Geração estimada",
        data: grafico.map(item=>item),
        backgroundColor: ['green']
      }]
    })
  }, []);

  useEffect(()=>{
    let labels = table2.map((item)=>item.title);
    let data = table2.map((item)=>item.value);

    setChartDougnut(
    {
      labels: labels,
      datasets: [{
        label: "Considereando o sistema instalado e sem dividas:",
        data: data,
        backgroundColor: ['#b6d7a8','#93c47d','#6aa84f','green']
      }]
    })
  }, []);

  const toNumber = (value) => {
    let qtdDecimal = value.split('.')[1]?.length;
    if(qtdDecimal === undefined) {
      qtdDecimal = 2;
    }
    let number = parseFloat(value.replace(",",".")).toFixed(qtdDecimal);
    return number;
    
  }

  const toCurrency = (value) => {
    let newValue = value.toString();
    newValue.replace(".","");
    return `R$ ${newValue}`

  }

  return (
    <div className="container">

      <div className="page" >
        <img className="headerImage" src={headerImage} />
        <img className="qrCode" src={qrcode} width={100} />
        <div className="header">
          <div className="textBig" style={{textAlign:'center'}} >PROPOSTA COMERCIAL</div>
          <div className="textBig" >DATA: {data?.header.data}</div>
          <div className="textBig">DIRETOR COMERCIAL: ALISSON LIMA - 62 99654-6596</div>
          <div className="textBig">REPRESENTANTE: {data?.header.diretor.toUpperCase()} - {data?.header.contato}</div>
          <div className="textBig">CLIENTE: {data?.header.cliente.toUpperCase()}</div>
        </div>
      </div>

      <div className="page" >
        <img className="floatImage" src={logoImage} width={50} />
        <div className="about" >
          <img className="image" src={logoImage} width={50} />
          <div className="textNormal" style={{marginBottom:30}} >A <b>SOLAR+</b> NASCEU DO DESEJO DE CUMPRIR O COMBINADO.<br/>
           ENXERGAMOS A NESCESSIDADE DE ATENDER NOSSOS CLIENTES COM O MÁXIMO DE DEDICAÇÃO POSSIVEL.<br/>
            NISSO, FUNDAMENTAMOS NOSSOS PILÁRES.
          </div>

          <div className="cardArea">
            <div className="card">
              <div className="cardText">
                <div className="textNormal">
                  NOSSA MISSÃO.
                </div>
                <div className="textNormal">
                LEVAR AOS NOSSOS CLIENTES, E PARCEIROS UMA SOLUÇÃO SÚSTENTAVEL. DE MODO QUE A SATISFAÇÃO MÚTUA SEJA O ALVO ALCANÇADO ATRAVÉS DE ESFORÇO E DEDICAÇÃO COM TRABALHO DE QUALIDADE.
                </div>
              </div>
            </div>

            <div className="card" style={{alignSelf:'end'}} >
              <div className="textNormal">
              NOSSA VISÃO.
              </div>
              <div className="textNormal">
              CONQUISTAR NOSSO ESPAÇO CONSTRUINDO UMA RELAÇÃO COM CADA CLIENTE, TRANSMITINDO A REALIDADE PARA QUE A SATISFAÇÃO POSSA NOS IMPULSIONAR A CRESCER A CADA DIA.
              </div>
            </div>

            <div className="card">
              <div className="textNormal">
              NOSSOS VALORES.
              </div>
              <div className="textNormal">
              AGILIDADE, DEDICAÇÃO, FOCO NO OBJETIVO COMUM DE QUE A EMPRESA ATENDA A PROPÓSTA, E O CLIENTE RECEBA COM EXATIDÃO NOSSOS SERVIÇOS PRESTADOS.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page">
        <img className="floatImage" src={logoImage} width={50} />
        <div className="system" >
          <div className="titleText" >
            <div className="textNormal" >
              Sistema:
            </div>
          </div>
          <img src={sistemaImage} className="sistemaImage" alt="" />
          <div className="textNormal" >
          Nesse sistema ligado à rede elétrica de <b>energia, os módulos fotovoltaicos captam</b> a radiação solar, convertendo-a em <b>energia</b> elétrica de corrente contínua. Ela é enviada ao inversor interativo para que seja transformada em corrente alternada. A energia excedente é enviada para a cocessionária, passando pelo medidor bidirecional se tornando credito para ser usado posteriormente.
          </div>
          <img src={placaSolar} alt="" />
          <div className="textNormal" >
          Em todo mercado, existem tecnologias que são mais criteriosas. Nós da <b>SOLAR+</b> damos prefêrencia em trabalhar com os melhores produtos do mercado, para atender com excelência aos nossos clientes. Como por exemplo a tecnologia <b>HALF-CELL</b>, que <b>aumenta a potência de saída dos painéis</b> e possibilita que os módulos apresentem menor perda por sombreamento
          </div>
        </div>
      </div>

      <div className="page" >
        <img className="floatImage" src={logoImage} width={50} />
        <div className="spent" >
          <div className="textBig" style={{textAlign:'center'}} >
            Quanto você gasta com energia?<br/>Já somou?
          </div>
          <div className="textSmall">VOCÊ PAGA EM MÉDIA POR KWh/Mês</div>
          <span className="cellTable" >R$ {data.consumo.valorKwMes}</span>
          <div className="textSmall">QUANTO VOCÊ GASTA EM MÉDIA DE KWh/Mês.</div>
          <span className="cellTable" >{data.consumo.consumoKwMes} KWh/Mês</span>
          <div className="table" >
            <table className="table2" border="1" >
              <tbody>
                <tr>
                  <td width="50%" >QUANTO VOCÊ PAGA POR CADA KWH/MÊS</td>
                  <td width="50%" >R$ {toNumber(data.consumo.valorKwMes).toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}</td>
                </tr>
                <tr>
                  <td>QUANTO VOCÊ PAGA EM MÉDIA POR KWH/MÊS</td>
                  <td className="red" >{(toNumber(data.consumo.valorKwMes)*toNumber(data.consumo.consumoKwMes)).toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}</td>
                </tr>
                <tr>
                  <td>DURANTE UM ANO </td>
                  <td className="red" >{table2[0].value.toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}</td>
                </tr>
                <tr>
                  <td>DURANTE 5 ANOS </td>
                  <td className="red" >{table2[1].value.toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}</td>
                </tr>
                <tr>
                  <td>DURANTE 10 ANOS</td>
                  <td className="red" >{table2[2].value.toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}</td>
                </tr>
                <tr>
                  <td>DURANTE 20 ANOS</td>
                  <td className="red" >{table2[3].value.toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="textBig" style={{alignSelf:'start'}}>QUAL SERÁ SEU GASTO COM ENERGIA?</div>
          <div className="chartPie">
            {chartPie &&
              <PieChart chartData={chartPie} /> 
            }
          </div>
        </div>
      </div>

      <div className="page" >
        <img className="floatImage" src={logoImage} width={50} />
        <div className="proposal" >
          <div className="textBig" style={{textAlign:'center',marginBottom:10}} >
          Propósta válida por 5 dias, ou enquanto durarem os estoques após esse período, revisar valores. Carência de até 120 dias a ver com o banco.<b>Simulação sujeita a analise bancária.</b>
          </div>
          <table className="proposalTable">
            <thead>
              <tr>
                <th colSpan="3">PROPOSTA:</th>
              </tr>
              <tr>
                <th colSpan="3">{data.proposta.valor}</th>
              </tr>
            </thead>
            {data.proposta.pagamento == "À Prazo" &&
              <tbody>
                <tr>
                  <td>48X</td>
                  <td>60X</td>
                  <td>72X</td>
                </tr>
                <tr>
                  <td>{data.proposta.parcelamento1}</td>
                  <td>{data.proposta.parcelamento2}</td>
                  <td>{data.proposta.parcelamento3}</td>
                </tr>
              </tbody>
            }
          </table>
          <div className="textBig"><b>Geração estimada:</b></div>
          <div className="textNormal">
          **SIMULAÇÃO**baseada no índice de incidência de irradiação Solar das regiões do Brasil por m2
          </div>
          <table className="proposalGeneration" border="1" >
            <thead>
              <tr>
                <th>REGIÃO</th>
                <th>UF</th>
                <th>IRMM2</th>
                <th>K2p</th>
                <th>MÉD/KWh/Mês</th>
              </tr>
            </thead>
            <tbody>
              <tr height="40" >
                <td>{data.geraRegiao.local}</td>
                <td>{data.geraRegiao.uf}</td>
                <td>{data.geraRegiao.irmm2}</td>
                <td>{data.geraRegiao.kwp}</td>
                <td>{data.geraRegiao.medKwMes}</td>
              </tr>
            </tbody>
          </table>
          <div className="chartBar">
            {chartBar &&
              <BarChart chartData={chartBar} />
            }
          </div>
          <div className="titleText" >
            <div className="textBig"><b>KIT GERADOR ON-GRID</b></div>
          </div>
          <table className="kitGerador" >
            <tbody>
              <tr>
                <td>{data.kit.marcaPainel}</td>
                <td>{data.kit.qtdPainel} UN</td>
              </tr>
              <tr>
                <td>{data.kit.inversor}</td>
                <td>{data.kit.qtdInversor} UN</td>
              </tr>
              <tr>
                <td>STRING BOX INTEGRADA</td>
                <td>Incluso</td>
              </tr>
              <tr>
                <td>CABO SOLAR 6MM PRETO</td>
                <td>Incluso</td>
              </tr>
              <tr>
                <td>CABO SOLAR 6MM VERMELHO</td>
                <td>Incluso</td>
              </tr>
              <tr>
                <td>CONECTOR MC4 MULTI-CONTACT UR PV-KBT4/6II-UR ACOPLADOR FEMEA</td>
                <td>Incluso</td>
              </tr>
              <tr>
                <td>CONECTOR MC4 MULTI-CONTACT UR PV-KST4/6II-UR ACOPLADOR MACHO</td>
                <td>Incluso</td>
              </tr>
              <tr>
                <td>JUNÇÃO PARA PERFIL DE ALUMÍNIO</td>
                <td>Incluso</td>
              </tr>
              <tr>
                <td>ESTRUTURA DE ALUMÍNIO PARAFUSO MADEIRA </td>
                <td>Incluso</td>
              </tr>
              <tr>
                <td>MATERIAL ELÉTRICO</td>
                <td>Incluso</td>
              </tr>
              <tr>
                <td>SERVIÇOS DE INSTALAÇÃO DO SISTEMA</td>
                <td>Incluso</td>
              </tr>
              <tr>
                <td>PROJETO SOLAR FOTOVOLTAICO </td>
                <td>Incluso</td>
              </tr>
              <tr>
                <td>ACOMPANHAMENTO JUNTO À DISTRIBUIDORA </td>
                <td>Incluso</td>
              </tr>
              <tr>
                <td>MONITORAMENTO DO SISTEMA VIA WEB </td>
                <td>Incluso</td>
              </tr>
              <tr>
                <td><b>Área Ocupada Pelo Sitema</b></td>
                <td><b>{data.kit.areaOcupada}</b></td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>

      <div className="page" >
        <img className="floatImage" src={logoImage} width={50} />
        <div className="economy" >
          <div className="textBig" >
          <b>JÁ CÁLCULOU QUAL SERÁ A SUA ECONOMIA INVESTINDO EM EFICIÊNCIA COM ENERGIA SOLAR?</b>
          </div>
          <div className="textSmall">VOCÊ PAGA EM MÉDIA POR KWh/Mês</div>
          <span className="cellTable" >R$ {data.consumo.valorKwMes}</span>
          <div className="textSmall">QUANTO VOCÊ GASTA EM MÉDIA DE KWh/Mês.</div>
          <span className="cellTable" >{data.consumo.consumoKwMes} KWh/Mês</span>
          <div className="table" >
            <table border="1" >
              <tbody>
                <tr>
                  <td width="50%" >CONSIDERANDO O SISTEMA INSTALADO E SEM DIVIDAS:</td>
                  <td width="50%">ECONOMIA EM R$ SEM CORREÇÃO</td>
                </tr>
                <tr>
                  <td>SEU RETORNO POR MÊS, É PROPORCIONAL</td>
                  <td></td>
                </tr>
                <tr>
                  <td className="blue" >ECONOMIA MENSAL</td>
                  <td className="green" >{(toNumber(data.consumo.valorKwMes)*toNumber(data.consumo.consumoKwMes)).toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}</td>
                </tr>
                <tr>
                  <td className="blue" >ECONOMIA ANUAL</td>
                  <td className="green" >{table2[0].value.toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}</td>
                </tr>
                <tr>
                  <td className="blue" >PRÓXIMOS 5 ANOS</td>
                  <td className="green" >{table2[1].value.toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}</td>
                </tr>
                <tr>
                  <td className="blue" >PRÓXIMOS 10 ANOS</td>
                  <td className="green" >{table2[2].value.toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}</td>
                </tr>
                <tr>
                  <td className="blue" >PRÓXIMOS 20 ANOS</td>
                  <td className="green" >{table2[3].value.toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}</td>
                </tr>
                <tr>
                  <td>QUANTOS KWH/MÊS O CLIENTE OCNSOME EM MÉDIA?</td>
                  <td>{data.consumo.consumoKwMes}</td>
                </tr>
                <tr>
                  <td>QUANTO CUSTA CADA QUILOWATT</td>
                  <td>R$ {data.consumo.valorKwMes}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="textBig" style={{textAlign:'start'}} >CONSIDERANDO O SISTEMA INSTALADO E SEM DIVIDAS:</div>
          <div className="chartPie">
            {chartDougnut &&
              <DoughnutChart chartData={chartDougnut} />
            }
          </div>
        </div>
      </div>

      <div className="page" >
        <img className="floatImage" src={logoImage} width={50} />
        <div className="payback" >
          <span className="cellTable" >RETORNO DO INVESTIMENTO</span>
          <div className="table" >
            <table border="1" className="blue"  >
              <tbody>
                <tr>
                  <td width="50%" >ÍNDICE DE RETORNO ANUAL</td>
                  <td width="50%">VALOR DO RETORNO EM R$:
  </td>
                </tr>
                <tr>
                  <td>1 ANO</td>
                  <td>{payback[0].toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}</td>
                </tr>
                <tr>
                  <td>2 ANOS</td>
                  <td>{payback[1].toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}</td>
                </tr>
                <tr>
                  <td>3 ANOS</td>
                  <td>{payback[2].toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}</td>
                </tr>
                <tr>
                  <td>4 ANOS</td>
                  <td>{payback[3].toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}</td>
                </tr>
                <tr>
                  <td>5 ANOS</td>
                  <td>{payback[4].toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}</td>
                </tr>
                <tr>
                  <td>6 ANOS</td>
                  <td>{payback[5].toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}</td>
                </tr>
                <tr>
                  <td>7 ANOS</td>
                  <td>{payback[6].toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}</td>
                </tr>
                <tr>
                  <td>8 ANOS</td>
                  <td>{payback[7].toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}</td>
                </tr>
                <tr>
                  <td>9 ANOS</td>
                  <td>{payback[8].toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}</td>
                </tr>
                <tr>
                  <td>10 ANOS</td>
                  <td>{payback[9].toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="chartBar">
            {chartBarHorizontal &&
              <BarChart chartData={chartBarHorizontal} />
            }
          </div>
        </div>
      </div>

      <div className="page" >
        <img className="floatImage" src={logoImage} width={50} />
        <div className="modules">
          <div className="textBig" ><b>SOBRE OS MÓDULOS</b></div>
          <img src={modulosImage} />
        </div>
      </div>

      <div className="page" >
        <img className="floatImage" src={logoImage} width={50} />
        <div className="plate">
          <img src={placaImage} />
          <div className="titleTextGray" >
            <div className="textBig" ><b>GARANTIA:</b></div>
          </div>
          <div className="textNormal" >
          Garantia Pelo Fabricante dos Itens:<br/><br/> Placas Fotovoltaicas: Monocristalinas com tecnologia PERC, 12 anos contra defeito de fabricação. E 25 anos, sobre desempenho linear.<br/><br/> Inversor Solar: 10 anos contra defeito de fabricação.
          </div>
        </div>
      </div>

      <div className="page" >
        <img className="floatImage" src={logoImage} width={50} />
        <div className="regularization" >
        <div className="titleTextGray" >
            <div className="textBig" ><b>GARANTIA:</b></div>
          </div>
          <div className="textNormal" >
          <b>A RESOLUÇÃO NORMATIVA Nº 687, DE 24 DE NOVEMBRO DE 2015,</b> Estabelece as condições gerais para o acesso de micro geração e geração distribuída (sistemas de energia solar e outros
geradores de energia renovável) aos sistemas de distribuição de energia elétrica, o sistema de compensação (créditos de energia). Também é conhecida por lei de incentivo a energia solar.<br/><br/>
LEI 13.169 06/10/15, Ficam reduzidas a zero as alíquotas da Contribuição para o PIS/Pasep e da
Contribuição para Financiamento da Seguridade Social - COFINS incidentes sobre a energia elétrica ativa fornecida pela distribuidora à unidade consumidora na quantidade correspondente à soma da energia elétrica ativa injetada na rede de distribuição pela mesma unidade consumidora com
os créditos de energia ativa originados na própria unidade consumidora no mesmo mês, em meses
anteriores ou em outra unidade consumidora do mesmo titular, nos termos do Sistema de Compensação de Energia Elétrica para micro geração e geração distribuída, conforme regulamentação da
Agência Nacional de Energia Elétrica – ANEEL<br/><br/>
<b>LEI</b> Nº 14.300, DE 6 DE JANEIRO DE 2022 Institui o marco legal da microgeração e minigeração distribuída, o Sistema de Compensação de Energia Elétrica (SCEE) e o Programa de Energia Renovável Social (PERS); altera as Leis nºs 10.848, de 15…
          </div>
        </div>
      </div>


    </div>
  )
}
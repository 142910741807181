export const ChartBar = [
  {
    id: 1,
    title: 'Jan',
    value: 40000,
  },
  {
    id: 2,
    title: 'Fev',
    value: 42000,
  },
  {
    id: 3,
    title: 'Mar',
    value: 38000,
  },
  {
    id: 4,
    title: 'Abr',
    value: 39000,
  },
  {
    id: 4,
    title: 'Mai',
    value: 35000,
  },
  {
    id: 4,
    title: 'Jun',
    value: 32000,
  },
  {
    id: 4,
    title: 'Jul',
    value: 37000,
  },
  {
    id: 4,
    title: 'Ago',
    value: 42000,
  },
  {
    id: 4,
    title: 'Set',
    value: 39000,
  },
  {
    id: 4,
    title: 'Out',
    value: 38000,
  },
  {
    id: 4,
    title: 'Nov',
    value: 39000,
  },
  {
    id: 4,
    title: 'Dez',
    value: 39000,
  }
];